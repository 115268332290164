import React from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const StyledCard = styled.div`
  border-radius: 2rem;
  box-shadow: 0 0.25rem 0.5rem 0.1rem #ddd;
  position: relative;
  color: #4a4a4a;
  max-width: 100%;
  background-color: white;
  border: 5px solid #fc7059;
`

const Books = ({ data }) => (
  <Layout>
    <SEO title="Hound Dog Bookstore" />

    <div className="columns has-text-centered is-centered">
      <div className="column is-4">
        <figure className="image is-128x128 is-inline-block mb-1">
          <img src="https://cms.hounddogreading.com/uploads/bookstore_290f4e6188.jpg" alt="Cartoon bookstore building" />
        </figure>
        <h2 className="title is-4">Hound Dog Bookstore</h2>
        <p className="subtitle">Short Sections from Popular Books</p>
      </div>
    </div>

    {data.allStrapiBook.edges.map(book => (
      <StyledCard className="card has-text-centered mb-6" key={book.node.id}>
        <div className="card-content columns">
          <div className="column is-2">
            <figure className="image">
              {book.node.cover ? (<img src={book.node.cover} alt="" />) : undefined}
            </figure>
          </div>
          <div className="column is-one-half">
            <p className="subtitle is-5">
              {book.node.url ? (<Link to={`/${book.node.url}`}>{book.node.Title}</Link>) : (<Link to={`/${book.node.id}`}>{book.node.Title}</Link>)}
            </p>
            <p>{book.node.Description}</p>
          </div>
          <div className="column is-5">
            <iframe width="336" height="189" title="book trailer video" src={book.node.video} frameborder="0" allowFullScreen></iframe>
          </div>
        </div>
      </StyledCard>
    ))}

  </Layout>
)
Books.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Books

export const pageQuery = graphql`
query BooksQuery {
    allStrapiBook {
      edges {
        node {
          Description
          Title
          id
          video
          url
          cover
        }
      }
    }
  }
  
`
